import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import anime from "animejs/lib/anime.es.js";
import OurMission from "../components/our-mission";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import WhatsOnItem from "../components/whats-on-item";
import NowPlaying from "../components/now-playing";
import moment from "moment";

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    anime({
      targets: ".gh-header h1 span",
      opacity: 1,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      duration: 3000,
      top: 0,
      delay: anime.stagger(100, { start: 1000 }),
    });
  }

  onHeadingHover() {
    anime({
      targets: ".gh-header h1 span",
      opacity: 0.5,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100),
    });
  }

  onHeadingLeave() {
    anime({
      targets: ".gh-header h1 span",
      opacity: 1,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100),
    });
  }

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Helmet>
          <meta
            property="og:image"
            content={data.headerImage1.childImageSharp.fluid.src}
          />
        </Helmet>
        <section className="gh-header">
          <div className="images">
            <Img
              fluid={data.headerImage3.childImageSharp.fluid}
              alt="Church in Logan"
            />
            <Img
              fluid={data.headerImage2.childImageSharp.fluid}
              alt="Church in Logan"
            />
            <Img
              fluid={data.headerImage1.childImageSharp.fluid}
              alt="Church in Logan"
            />
          </div>
          <h1
            onMouseEnter={() => this.onHeadingHover()}
            onMouseLeave={() => this.onHeadingLeave()}
          >
            <span className="alt">Build</span> <span>His</span>{" "}
            <span>Temple.</span>
          </h1>
          <NowPlaying
            times={[
              {
                name: "Sunday Service",
                url: "https://www.youtube.com/channel/UC7ko9KyfJ5PS9eOuhjuWmbQ",
                startTime: {
                  dayOfWeek: 7,
                  hours: 9,
                  minutes: 30,
                },
                endTime: {
                  dayOfWeek: 7,
                  hours: 12,
                  minutes: 0,
                },
              },
            ]}
          />
          <NowPlaying
            times={[
              {
                name: "Prayer Meeting",
                url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                startTime: {
                  dayOfWeek: 1,
                  hours: 6,
                  minutes: 45,
                },
                endTime: {
                  dayOfWeek: 1,
                  hours: 7,
                  minutes: 30,
                },
              }, {
                name: "Prayer Meeting",
                url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                startTime: {
                  dayOfWeek: 3,
                  hours: 6,
                  minutes: 45,
                },
                endTime: {
                  dayOfWeek: 3,
                  hours: 7,
                  minutes: 30,
                },
              }, {
                name: "Prayer Meeting",
                url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                startTime: {
                  dayOfWeek: 5,
                  hours: 6,
                  minutes: 45,
                },
                endTime: {
                  dayOfWeek: 5,
                  hours: 7,
                  minutes: 30,
                },
              }
            ]}
          />

        </section>
        <section className="gh-top-links">
          <AniLink fade to="/sermons">
            <span>Sermons</span>
          </AniLink>
          <AniLink fade to="/contact">
            <span>Contact Us</span>
          </AniLink>
        </section>
        <OurMission />
        <section className="gh-whats-on">
          <div className="divider"></div>
          <div className="container">
            <h2>
              What's <span>On</span>
            </h2>
            <div className="event-container">
              <div className="event-container-left">
                <WhatsOnItem
                  compact={true}
                  title="Sunday Service"
                  url="https://www.youtube.com/channel/UC7ko9KyfJ5PS9eOuhjuWmbQ"
                  extraTitle={"Church"}
                  isYouTube={true}
                  extraText={"Sun 9:30am"}
                  times={[
                    {
                      startTime: {
                        dayOfWeek: 7,
                        hours: 9,
                        minutes: 30,
                      },
                      endTime: {
                        dayOfWeek: 7,
                        hours: 12,
                        minutes: 0,
                      },
                    },
                  ]}
                />
                <div className="event-item">
                  <h3>Community Groups</h3>
                  <p>Contact us at <a className="generic-link" href="mailto:admin@gracehouse.com.au">admin@gracehouse.com.au</a></p>
                </div>
              </div>
              <div className="event-container-right">
              <WhatsOnItem
                rtl={true}
                title="Prayer Meetings"
                extraText={"Mon 7:00am, Wed 7:00am & Fri 7:00am"}
                url="https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1"
                extraTitle={"Church"}
                subtitle={"Meeting ID: 814 2263 4039. Password: Gracehouse"}
                times={[
                  {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 1,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 1,
                      hours: 7,
                      minutes: 30,
                    },
                  }, {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 3,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 3,
                      hours: 7,
                      minutes: 30,
                    },
                  }, {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 5,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 5,
                      hours: 7,
                      minutes: 30,
                    },
                  }
                ]}
              />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    headerImage1: file(relativePath: { eq: "lans.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage2: file(relativePath: { eq: "theunis.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage3: file(relativePath: { eq: "worship.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
